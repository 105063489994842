<template>
  <div>
      <div class="container">
        <app-bar
          title="Kode Referal"
          :previousLink="{ name: 'Profil' }"
        />
        <div class="row px-3">
          <button
            v-if="referalCode"
            class="btn w-100 btn-primary btn-detail py-2 mb-3"
            data-bs-toggle="modal"
            data-bs-target="#modalReferalCode"
          >
            <span class="fw-600 fs-12 lh-18">Tampilkan Kode Referal</span>
          </button>

          <Loading v-if="loading.referalList" />
          <Empty v-else-if="referalList.length === 0" :message="'Saat ini belum ada list referal yang bisa ditampilkan.'"/>

          <div v-else>
            <div v-for="referal in referalList" :key="referal.id" class="layanan">
              <div class="d-flex align-items-center border-none">
                <img class="me-3" src="@/assets/icon/profile-circle.svg" width="42" alt="">
                <div>
                  <span class="fs-14 fw-700 color-neutral-900 d-block"> {{ referal.nama_pasien }} </span>
                  <span class="fs-12 fw-400 color-neutral-900"> {{ referal.tgl_periksa }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade"
        id="modalReferalCode"
        tabindex="-1"
        aria-labelledby="modalReferalCode" aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body text-center d-flex flex-column py-3">
                <h5 class="modal-title">Kode Referal</h5>
                <div id="qr-referal" class="mt-3"></div>
                <div class="group-button pb-2 pt-3 d-flex gap-3">
                  <button
                    type="button"
                    class="btn btn-info text-white w-50"
                    @click="shareFunction"
                  >
                    Bagikan
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger text-white w-50" data-bs-dismiss="modal">Tutup</button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { nextTick, onMounted, ref } from 'vue'
import { title } from '@/core/page'
import { getReferalCode, referalCode, getReferalList, referalList, loading } from '@/core/profile'
import { qrcanvas } from 'qrcanvas'
import { showNotif } from '@/core/utility'
import { Modal } from 'bootstrap'

export default {
  components: { AppBar, Loading, Empty },
  setup () {
    const canvasQr = ref()
    const prepareReferalCode = async () => {
      await getReferalCode()
      const container = document.getElementById('qr-referal')
      container.innerHTML = ''
      canvasQr.value = qrcanvas({
        cellSize: 13,
        data: referalCode.value,
        foreground: [
          { style: '#007A98' }
        ]
      })
      container.appendChild(canvasQr.value)
    }

    const shareFunction = async () => {
      const modal = Modal.getOrCreateInstance(document.querySelector('#modalReferalCode'))

      const canvasElement = document.getElementById('qr-referal').children[0]
      const dataUrl = canvasElement.toDataURL()
      const blob = await (await fetch(dataUrl)).blob()
      const filesArray = [
        new File(
          [blob],
          'qr-referal.png',
          {
            type: blob.type,
            lastModified: new Date().getTime()
          }
        )
      ]
      const dataToShare = {
        files: filesArray,
        title: 'Kode Referal',
        text: `Gunakan Aplikasi Parahita, Dapatkan potongan harga dengan mengunakan kode referral = *${referalCode.value}*

Download aplikasi *Lab Parahita* sekarang juga baik di Play Store dan App Store(pengguna Android & IOS)`
      }

      // feature detecting navigator.canShare() also implies
      // the same for the navigator.share()
      if (!navigator.canShare) {
        modal.toggle()
        // Trigger toast
        showNotif({
          status: 'error',
          message: 'Browser Anda tidak mendukung fitur share, silahkan screenshot QR Code jika ingin membagikannya'
        })
        return
      }

      if (navigator.canShare(dataToShare)) {
        try {
          await navigator.share(dataToShare)
          modal.toggle()
          // Trigger toast
          showNotif({
            status: 'success',
            message: 'Berhasil membagikan Referal'
          })
        } catch (error) {
          modal.toggle()
          // Trigger toast
          showNotif({
            status: 'error',
            message: `Error: ${error.message}`
          })
        }
      } else {
        modal.toggle()
        // Trigger toast
        showNotif({
          status: 'error',
          message: 'Browser Anda tidak mendukung fitur share untuk tipe ini, silahkan screenshot QR Code jika ingin membagikannya'
        })
      }
    }

    onMounted(async () => {
      await prepareReferalCode()
      await getReferalList()
    })

    nextTick(() => {
      title.value = 'Kode Referal'
    })

    return {
      referalCode,
      referalList,
      loading,
      shareFunction
    }
  }
}
</script>

<style lang="scss" scoped>
.layanan {
  padding: 12px 0px;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
}

.layanan-parrent .layanan:last-child {
  box-shadow: none;
}
</style>
